#Addcountrytable th {
    text-align: center;
    }
    
    #Addcountrytable td:nth-child(1),
     #Addcountrytable td:nth-child(6){
        text-align: left;
     }
    // #Addcountrytable td:nth-child(10){
    //   text-align: center;
    // }

    #Addcountrytable td:nth-child(10){
      text-align: left;
      width:3%;
    }
    #Addcountrytable td:nth-child(9){
      text-align: left;
      width:5%;
    }