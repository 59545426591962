// #addUserTableId th{
//   text-align: center;
// }
// #addUserTableId td:nth-child(8),
// #addUserTableId td:nth-child(7),
// #addUserTableId td:nth-child(1),
// #addUserTableId td:nth-child(2),
// #addUserTableId td:nth-child(4){
//   text-align: center;
// }

#userTablesId td:nth-child(1),
#userTablesId td:nth-child(4) {
    text-align: center;
}
#userTablesId td:nth-child(3) {
    text-align: left !important;
}
#userTablesId td:nth-child(1){
    width: 2px;
}
#userTablesId th{
    text-align: center;
}
