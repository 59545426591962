

.resultmain .detailsp{
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: #282828 !important;
}

.resulticon{
    width: 30px;
}

.resultweight{
    position: absolute;
    right: 3.5rem !important;
    top: 1.5rem;
     font-size: 1.1rem !important;
        font-weight: 700 !important;
        color: #282828 !important;
}

.maindetailcard{
    background-color: #f9f6f3 !important;
   max-height: 50vh !important;
   overflow-y: auto;
   box-shadow: none !important;
  }

  .resultcardtrack{
    cursor: pointer !important;
   border-radius: .5rem !important;
   padding: 0 !important;
   margin: .5rem !important;
   height: unset !important;
}

.resultcardbodytrack{
    padding: 0rem 1.25rem !important;
}

.trackingcard-cardbody {
    padding: 0px !important;

}
.select2-selection {
    overflow: scroll;
}

.app-search {
    margin: 0px !important;
    padding: 1.8vh !important ;
}

// .resultcardtrackbox{
//     cursor: pointer !important;
//     border-radius: .5rem !important;
//     padding: 0 !important;
//     margin: 0 !important;
//     height: auto !important;
//  }

 .resultmain{
    display: flex;
    
}

.fields {
    margin-top: 10px;
    margin-bottom: 10px;
}

.maincard {
    background-color: #f9f6f3 !important;
    max-height: 50vh !important;
    overflow-y: auto;
    box-shadow: none !important;
    width: 122%;
    cursor: pointer;
    padding: 4px;
}

.maindetailcard::-webkit-scrollbar {
    width: 5px  !important;
    padding: 5px;
}
