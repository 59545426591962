#shipTableId td:nth-child(1),
#shipTableId td:nth-child(2),
#shipTableId td:nth-child(3),
#shipTableId td:nth-child(4) {
    text-align: center;
}
#shipTableId td:nth-child(1){
    width: 3px;
}
#shipTableId th{
    text-align: center;
}
#itemTableId th{
    text-align: center;
}
#itemTableId  td:nth-child(1){
    text-align: center;
}
#itemNewTableId  th{
    text-align: center;
}
#itemNewTableId  td:nth-child(1){
    text-align: center;
}
#itemNewTableId  td:nth-child(7),
#itemNewTableId  td:nth-child(6){
    text-align: right;
}

.shipmnt-ship {
    font-size: 2rem;
    font-weight: 600;
}