.processinbound {
    font-size: 1.25rem;
    font-weight: 700;
    color: #282828;
}

.boundcardbody {
    padding-top: 0px;
}

.srhcol {
    display: flex;
    margin-top: 0rem !important;
}

.srhcol input{
    width: 100%;
    padding: 10px 15px;
    border-radius: .5rem;
    border:1px solid #ccc;
    outline: 0;
    font-size: 1rem !important;
    margin-top: 10px;
}

.resultcardtrack{
    cursor: pointer !important;
   border-radius: .5rem !important;
   padding: 0 !important;
   margin: .5rem !important;
   height: unset !important;
}

.resultcardbodytrack{
    padding: 0rem 1.25rem !important;
}

.resultcardtrack{
    cursor: pointer !important;
   border-radius: .5rem !important;
   padding: 0 !important;
   margin: .5rem !important;
   height: unset !important;
}

.resultcardbodytrack{
    padding: 0rem;
}

// .maindetailcard1 {
//     background-color: #f9f6f3 !important;
//     max-height: 50vh !important;
//     box-shadow: none !important;
//  }
 
.resultcard1 {
    cursor: pointer !important;
   border-radius: .5rem !important;
   padding: 0 !important;
   margin: .5rem !important;
   height: unset !important;
}

.resultcardbody1 {
    padding: 0rem 1.25rem !important;
}

.resultmain .detailsp{
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: #282828 !important;
}

.resultweight2 {
    position: absolute;
    right: 0.5rem !important;
    top: 2.5rem;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    color: #282828 !important;
}



