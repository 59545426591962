#citytable th{
  text-align: center;
}

#citytable td:nth-child(7),
#citytable td:nth-child(5),
#citytable td:nth-child(1){
  text-align: center;
}

#citytable td:nth-child(4){
  text-align: right;
  // width: 60px;
}



