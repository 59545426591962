.addpackinglist {
  font-size: 1.25rem;
  font-weight: 700;
  color:#282828 ;
}

.packingcard {
  height: auto !important;
  border-radius: .5rem !important;
  padding: 0px !important;
}

.searchcol{
  display: flex;
  margin-top: 0rem !important;
}

.searchcol input{
  width: 67%;
  padding: 10px 15px;
  border-radius: .5rem;
  border:1px solid #ccc;
  outline: 0;
  font-size: 1rem !important;
}


.maindetailcard1{
  background-color: #f9f6f3 !important;
//  max-height: 50vh !important;
//  overflow-y: auto;
 box-shadow: none !important;
}

.resultcard{
  cursor: pointer !important;
 border-radius: .5rem !important;
 padding: 0 !important;
 margin: .5rem !important;
 height: unset !important;
}

.resultcardbody {
  padding: 0rem 1.25rem !important;
}

.resultweight1 {
  position: absolute;
  right: 1.5rem !important;
  top: 1.5rem;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #282828 !important;
}

.resultweight {
  position: absolute;
  right: 1.5rem !important;
  top: 1.5rem;
  font-size: 1.1rem !important;
  font-weight: 700 !important;
  color: #282828 !important;
}

.resultcardbody1 {
  padding: 0rem 1.25rem !important;
}

.maindetailcard1 {
  background-color: #f9f6f3 !important;
  // max-height: 50vh !important;
  max-height: 50vh !important;
   overflow-y: auto;
  box-shadow: none !important;
  width: 122%;
}
  
.addnw1 {
  display: flex;
  justify-content: center;
}

// .addspan:hover {
//   background-color: #f9f6f3;
// }

.pagination {
  z-index: 0;
  position: relative;
}

