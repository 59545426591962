// #daywiseReportId td:nth-child(3),
// #daywiseReportId td:nth-child(5),
// #daywiseReportId td:nth-child(6){
//     text-align: right;
// }
#CustomerReportTableId td:nth-child(1),
#CustomerReportTableId td:nth-child(3) {
    text-align: center;
}

#CustomerReportTableId th{
    text-align: center;
}
#CustomerReportTableId td:nth-child(2){
   min-width: 100px;
   text-align: left;
}
#CustomerReportTableId td:nth-child(1){
    width: 2px;
}