.update {
    font-size: 1.25rem;
    font-weight: 700;
    color: #282828;
}

.searchcol .search {
    background-color: #eb6517;
    border: none;
    width: 45px;
    border-radius: 0.35rem;
    margin-left: 10px;
}

.detailcard {
    background-color: #f9f6f3;
    max-height: 50vh;
    width: 122%;
    box-shadow: none;
    overflow-y: auto;
}

.subcard {
    cursor: pointer;
    margin: .5rem;
}

.subcardbody {
    padding: 0rem 1.25rem;
}

.resulticon {
    width: 30px;
}

.maindiv .detailsp {
    color: #282828;
    font-size: 1.1rem;
    font-weight: 700;
}