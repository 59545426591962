.dashcard {
  display: flex;
  flex-flow: wrap;
  margin: 0 -15px;
}

.dashcard .item {
  flex: 1 0 25%;
  padding: 0 15px;
}

.dashcard .item .cardbox {
  background-color: #fff;
  border-radius: .75rem;
  padding: 25px 20px;
  display: flex;
  flex-flow: column;
  position: relative;
  box-shadow: 20px 20px 20px 0 RGB(0 0 0 / 5%);
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.dashcard .item .cardbox:hover {
  background-color: #282828;
  color: #eee;
}

.dashcard .item .cardbox .contbox {
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  margin-top: 0.5rem;
}

.dashcard .item .cardbox .topicon {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: .25;
  color: #eb6517;
}

.dashcard .item .cardbox .icon {
  width: 50px;
  height: 50px;
  background-color: #282828;
  color: #eb6517;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.dashcard .item .cardbox .icon i {
  transition: none;
  -webkit-transition: none;
}

.dashcard .item .cardbox:hover .icon {
  color: #282828;
  background-color: #eb6517;
}

.dashcard .item .cardbox .mainval {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.dashcard .item .cardbox .subval {
  margin-left: 10px;
  line-height: normal;
  font-size: .8rem;
  opacity: .75;
}

.dashcard .item .cardbox .subval span {
  display: block;
}

.dashcard .item .cardbox:hover .title {
  color: #fcfcfc;
  // background-color: #eb6517;
}

.dashcard .item .cardbox .title {
  font-size: 1rem;
  font-weight: 700;
  color: #282828;
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #282828;
  color: #eb6517;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.mainval {
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.subval {
  margin-left: 10px;
  line-height: normal;
  font-size: .8rem;
  opacity: .75;
}

.booking {
  display: flex;
  align-items: center;
  justify-content: center;

}

.bookingcard {
  border-radius: 15px !important;
}

.headingbook {
  text-align: center;
  color: #282828;
  font-weight: 600;
}

.bookingcard:hover {
  border: 2px solid black !important;
  cursor: pointer;
}

.bookingcard {
  border: 2px solid transparent !important;
}

//complaint

// .complist{
//   position: fixed;
//   bottom: 9rem;
//   right: 0;
//  z-index:1;

// }
// .compbtn{
//      border: none !important;
//    position: fixed;
//    bottom: 9rem;
//    right: 0;
//    height: 40px;
//    width: 136px;
//    z-index: 99;
//    font-size: .875rem;
//  background-color: white !important;
//   color: black !important;
//   border-radius: 2rem 0 0 2rem !important;
//   font-weight: 700 !important;
//   box-shadow: -4px 4px 20px RGB(0 0 0 / 15%);
// }
// .compbtn i{
//   margin-right: 5px;
//   transition: none;
// }
// .compbtn:hover{
//   color: #eb6517 !important;
// }
// .complaintlist{
//   position: absolute;
//     bottom: 40px;
//     right: 0;
//     width: 0;
//     background-color: #fff;
//     border-radius: .5rem 0 0 .5rem;
//     overflow: hidden;
//     padding-bottom: 10px;
//     box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
//     opacity: 0;
//     transition: all .3s linear;
//     -webkit-transition: all .3s linear;


// }
// .complaintbox{
//   position: absolute;
//   bottom: 40px;
//   right: 0;
//   width: 330px;
//   background-color: #fff;
//   border-radius: .5rem 0 0 .5rem;
//   overflow: hidden;
//   padding-bottom: 10px;
//   box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
//   opacity: 1;
//   transition: all .3s linear;
//   -webkit-transition: all .3s linear;


// }

// .comphead{
//   background-color: #eb6517;
//   color: #fff !important;
//   padding: 8px 15px !important;
//   font-weight: 600 !important;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   white-space: nowrap;
//   border: none !important;
// }
// .comitems{
//   display: flex;
//   flex-flow: column;
//   max-height: 350px;
//   overflow: hidden;
//   overflow-y: auto;
//   padding-left: 0rem!important;
// }
// .compitembox{
//   display: flex;
//   align-items: center !important;
//   padding: 8px 15px !important;
//   transition: all .3s ease;
//   -webkit-transition: all .3s ease;
// }
// .compitembox:hover{
//   background-color: #fdf2ec;
// }
// .compdatecol{
//   width: 60px;
//   font-size: .875rem;
//   display: flex;
//   flex-flow: column;
//   align-items: center;
//   justify-content: center;
//   text-align: center;
//   background-color: #ffffff;
//   height: 50px;
//   border-radius: 0.3rem;
//   box-shadow: 3px 4px 16px RGB(0 0 0 / 10%);
// }
// .comitems.compitembox.compdatecol.dd{
//   font-weight: 700 !important;
//   color: #eb6517;
// }
// .comitems.compitembox.comdataco{
//   width: calc(100% - 60px);
//   padding-left: 10px;
// }
// .comitems h6{
//   margin-bottom: 0;
//   font-size: .9rem;
//   max-height: 18px;
//   overflow: hidden;
// }
// .comitems p{
//   font-size: .875rem;
//   color: #666;
//   max-height: 40px;
//   overflow: hidden;
//   margin: 0;
// }


//========ebd

// new comptodo start
.todolistcomp {
  position: fixed;
  bottom: 1rem !important;
  right: 0;
  z-index: 99;
}

.todobtncomp {
  border: none !important;
  position: fixed;
  bottom: 3.5rem;
  right: 0;
  padding: 0 15px !important;
  color: black !important;
  font-size: .875rem !important;
  height: 40px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border-radius: 2rem 0 0 2rem !important;
  font-size: .875rem !important;
  box-shadow: -4px 4px 20px RGB(0 0 0 / 15%) !important;
  z-index: 99;
}

.todobtncomp:hover {
  color: #eb6517 !important;
}


.compbox {
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 0;
  background-color: #fff;
  border-radius: .5rem 0 0 .5rem;
  overflow: hidden;
  padding-bottom: 10px;
  box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
  opacity: 0;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
}

.compboxshow {
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 330px;
  background-color: #fff;
  border-radius: .5rem 0 0 .5rem;
  overflow: hidden;
  padding-bottom: 10px;
  box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
  opacity: 1;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
}


//new comptodo end


//TODOBOX

.todolist {
  position: fixed;
  bottom: 3rem !important;
  right: 0;
  z-index: 99;
}

.todobtn {
  border: none !important;
  position: fixed;
  bottom: 7rem;
  right: 0;
  padding: 0 15px !important;
  color: black !important;
  font-size: .875rem !important;
  height: 40px !important;
  font-weight: 700 !important;
  background-color: #fff !important;
  border-radius: 2rem 0 0 2rem !important;
  font-size: .875rem !important;
  box-shadow: -4px 4px 20px RGB(0 0 0 / 15%) !important;
  z-index: 99;
}

.todobtn i {
  margin-right: 5px;
  transition: none;
}

.todobtn:hover {
  cursor: pointer !important;
  color: #eb6517 !important;
}

// .todolist .todobtn.hide i:before{
//   content: '\f068';
//   font-weight: 600;
// }
.todobtn:hover {
  color: #eb6517 !important;
}

.todobox {
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 0;
  background-color: #fff;
  border-radius: .5rem 0 0 .5rem;
  overflow: hidden;
  padding-bottom: 10px;
  box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
  opacity: 0;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;

}

.todoboxshow {
  position: absolute;
  bottom: 40px;
  right: 0;
  width: 330px;
  background-color: #fff;
  border-radius: .5rem 0 0 .5rem;
  overflow: hidden;
  padding-bottom: 10px;
  box-shadow: -5px 5px 20px RGB(0 0 0 / 20%);
  opacity: 1;
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
}

.todohead {
  background-color: #eb6517;
  color: #fff !important;
  padding: 8px 15px !important;
  font-weight: 600 !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  border: none !important;
}

//  .closebtn{
//   position: absolute;
//   right: 0;
//   top: 0;
// }
.todoitems {
  display: flex;
  flex-flow: column;
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 0rem !important;
}

.itembox {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
}

.itembox:hover {
  background-color: #fdf2ec;
}

.datecol {
  width: 60px;
  font-size: .875rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  height: 50px;
  border-radius: 0.3rem;
  box-shadow: 3px 4px 16px RGB(0 0 0 / 10%);
}

.todoitems .itembox .datecol .dd {
  font-weight: 700;
  color: #eb6517;
}

.todoitems .itembox .datacol {
  width: calc(100% - 60px);
  padding-left: 10px;
}

.todoitems h6 {
  margin-bottom: 0;
  font-size: .9rem;
  max-height: 18px;
  overflow: hidden;
}

.todoitems p {
  font-size: .875rem;
  color: #666;
  max-height: 40px;
  overflow: hidden;
  margin: 0;
}

.quickrderh5 {
  font-weight: 700;
}

.dashboardh1 {
  font-size: 1.2rem;
  font-weight: 700;
}

.flagicon {
  border: 2px solid transparent !important;
  border-radius: 10px !important;
  // height: 100%;
  // justify-content: center;
  // align-items: center;
}

.flagicon:hover {
  cursor: pointer;
  border: 2px solid black !important;
}

.flagimage {
  width: 44px;
}

.flagiconh6 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #282828 !important;
}

.bookiconbtn {
  width: 36px;
}

.bookingbtncardbody {
  display: flex !important;
  flex-flow: wrap !important;
  align-items: center;
  justify-content: center;
  padding: 0.5rem !important;
}

.bookingbtncard {
  border-radius: .8rem !important;
  cursor: pointer;
}

.bookbtnp {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.complaintlist {
  position: fixed;
  right: 0;
  bottom: 8rem !important;
}

.complaintlist .complaintbtn {
  border: none !important;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 15px;
  height: 40px;
  width: 136px;
  font-weight: 700 !important;
  background-color: #fff !important;
  border-radius: 2rem 0 0 2rem !important;
  font-size: .875rem !important;
  color: #282828 !important;
  box-shadow: -4px 4px 20px RGB(0 0 0 / 15%);
}

.complaintlist .complaintbtn:hover {
  color: #eb6517 !important;
}

.title-pop {
  text-align: center;
}

.dash-cargo {
  display: flex;
  flex-flow: wrap;
  width: 100%;
}

.dash-cargo li {
  width: 50%;
  padding: 0 8px;
}

.dash-cargo li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  color: #333;
  border-radius: 1.3rem;
}

.dash-cargo li img {
  width: 36px;
  margin-right: 8px;

}

// =============
.cargoicon {
  border: 5px;
  border-radius: 19px !important;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 823%;
  flex-wrap: wrap;
  padding: -9px 13px;
}

span.single-line {
  white-space: nowrap;
  font-size: 16px;
  // font-weight: bold;
  color: #333;
  // text-transform: uppercase;
  display: flex;
  align-items: center;
}

.cargomodal {
  display: block;
  padding-left: 5px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

// .cargomodal .modal-content {
//   // background-color: #fff3f3;
//   background: #000;
//   border: none;
//   color: #efefef;
//   // padding: 2rem 0;
//   padding-bottom: 3rem;
// }

.cargomodal .modal-content {
  background: #000;
  border: none;
  color: #efefef;
  padding-bottom: 3rem;
  padding: 2rem 0;
}

.cargomodal .modal-content h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: #fff;
}

.flagicon1 {
  border: 2px solid #000 !important;
  border-radius: 10px !important;
  width: 200px;
}

.flagicon1:hover {
  cursor: pointer;
  border: 2px solid black !important;
}

.transparent-backdrop.modal-backdrop {
  opacity: 0.15 !important;
}

.carg-img:hover {
  transform: translateY(-1px);
}

.close-btn-btn {
  position: absolute;
  bottom: -1rem;
  right: 0;
  left: 0;
  margin: auto;
  color: #000;
  outline: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  border: 1px solid #fff;
  transition: all .3s ease;
}

