#AddComplaintstatustable th {
  text-align: center;
}

#AddComplaintstatustable td:nth-child(6),
#AddComplaintstatustable td:nth-child(10) {
  text-align: center;
}

#AddComplaintstatustable td:nth-child(1) {
  text-align: left;
}
