#custTablesId td:nth-child(1),
#custTablesId td:nth-child(3){
    text-align: center;
}
#custTablesId td:nth-child(4) {
    text-align: left !important;
}
#custTablesId td:nth-child(1){
    width: 2px;
}
#custTablesId th{
    text-align: center;
}