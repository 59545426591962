.bulkorder {
    font-size: 1.25rem;
    font-weight: 700;
    color: #282828;
}

.singleordercard {
    height: auto !important;
    border-radius: .5rem !important;
    padding: 0px !important;
}

.searchcol {
    display: flex;
    margin-top: 0rem !important;
}

.searchcol input {
    width: 100%;
    padding: 10px 15px;
    border-radius: .5rem;
    border: 1px solid #ccc;
    outline: 0;
    font-size: 1rem !important;
}

.searchcol .searchbtn {
    width: 70px;
    background-color: #eb6517;
    border-radius: 0.25rem;
    margin-left: 10px;
    //color: #fff;
    border: none !important;
}

.maincard {
    background-color: #f9f6f3 !important;
    max-height: 50vh !important;
    overflow-y: auto;
    box-shadow: none !important;
    width: 122%;
}


.resultcardbody {
    padding: 0rem 1.25rem !important;
}

.resultcard {
    cursor: pointer !important;
    border-radius: .5rem !important;
    padding: 0 !important;
    margin: .5rem !important;
    height: unset !important;
    width: 90%;
}

.resultcard2 {
    border-radius: 1.5rem;
}

.resultcardbody2 {
    padding: 0rem;
}

.resultweight2 {
    position: absolute;
    right: 1.5rem !important;
    top: 1.5rem;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: #282828 !important;
}
