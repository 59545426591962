
.deliver-badge {
  // animation: blinker 2s linear infinite;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  position: absolute;
  top: -9px;
  background-color: #eb6517b5 !important;
  padding: 0px 10px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  height: 18px;
  color: black !important;
  font-size: 10px;
  font-weight: 500;
  border-radius: 15px;
}
// @keyframes blinker {
//   50% {
//     opacity: 0;
   
//   }
  
// }
.editupdate{
  display: flex;
    gap: 8px;
    position: absolute;
    top: 1.4rem;
    right: 1rem;
    font-size: 1rem;
    z-index: 99;
}

// .deliver-badge i{
//   font-size: 10px;
//   line-height: 18px;
// }

// .page-content-success{
//   height: 60vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

 .card.deliver{
  border:3px solid white !important;
 cursor: pointer;
 }

  .deliver:hover {
  border: 3px solid #eb6517 !important;
  background-color: #fbfbfb;
  //transform: scale(1.05);
  transition: .2s;
}

.international {
  padding-top: 100px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #f9f6f3;
}

.navhead {
  font-size: larger !important;

}

.nav-link {
  padding: 0.5rem 4rem !important;
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link {
  padding: 10px 20px !important;
  border-radius: 0.5rem !important;
  background-color: transparent !important;
  color: #282828 !important;
  border: none !important;
  display: flex !important;
  border: 1px solid !important;
  margin: 0 10px !important;

}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
  background-color: #eb6517 !important;

}

.nav-link:hover,
.nav-link:focus {
  color: #eb6517 !important;
}

.nav-pills .nav-link :active {
  color: #ffffff !important;
  background-color: #eb6517 !important;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;

}

.table-bordered {
  border-color: #f1f1f3 !important;
}

.addnewbtn {
  padding: 5px 15px !important;
  border-radius: 0.5rem !important;
  background-color: #fbd9c4 !important;
  font-size: .75rem !important;
  color: #333 !important;
  display: block !important;
  font-weight: 600 !important;
  margin: auto !important;
  margin-top: -2rem !important;
  position: relative !important;
  opacity: 0 !important;

}

.addnewbtn:hover {
  background-color: #ffba8e !important;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 1px !important;
  background-color: #fff;
}

thead {
  border-width: 1px !important;
  background-color: #fff;
}

.content {
  text-align: left;
  margin-top: 0 !important;

}

.content1 {
  text-align: right;
}

.imglogo {
  width: 100%;
  margin-left: 40px
}

.headcontent {
  display: flex;
  justify-content: center;
}

.alfarha {
  font-weight: 700;
  color: #282828
}

.alfarha1 {
  font-size: 3rem;
  color: #282828;
}

.icons {
  color: #eb6517;
}

.details {
  color: #666 !important;
}

.sumhead {
  background-color: #ffe2d1;
  padding: 8px 15px;
  border-radius: 0.3rem;
  color: #eb6517;
}

.tablecell {
  width: 0px;
  text-align: center !important;
  font-weight: bold;
  padding-left: 0rem;
  padding-right: 0rem;
  font-weight: 700;
  font-size: 12.819px;
  color: #495057;
}

.sumcontent {
  display: flex !important;
  justify-content: center !important;

}

.btnlist {
  display: flex;
  justify-content: flex-end;

  margin-top: 1rem;
}

.btnlist .back {
  padding: 8px 20px;
  border-radius: 2rem;
  background-color: #282828;
  color: #fff;
  border: none;


}

.btnlist .refresh {
  // padding: 0 20px;

  border-radius: 2rem;
  background-color: #282828;
  color: #fff;
  border: none;


}

.btnlist .refresh:hover {
  background-color: #535157;
  box-shadow: 12px 9px 20px;
}

.btnlist .back1 {
  padding: 8px 20px;
  border-radius: 2rem;
  background-color: #eb6517;
  color: #fff;
  border: none;


}

.btnlist .submit {
  width: 120px;
  border-radius: 2rem;
  background-color: #eb6517;
  color: #fff !important;
  border: none;
}

.btnlist .next {
  width: 100px;
  height: 30px;
  margin-left: 10px;
  border-radius: 2rem;
  background-color: #eb6517;
  color: #fff !important;
  border: none;
  margin-right: 10px;
}

.btnlist .submit:hover {
  background-color: #cd4b00;
}

.btnlist .back:hover {
  background-color: #535157;
  box-shadow: 12px 9px 20px;
}

.btnlist .back1:hover {
  background-color: #cd4b00;
}

.tab-content {
  background-color: #fff !important;
}

.thcontent {
  width: 200px;
  color: #666;
  font-weight: 700;

}

.tdcontent {
  padding-right: 10px;
}

.totalcontent {
  color: #eb6517 !important;
  font-weight: 700;

}

.invoice {
  font-weight: 700;
  color: #333 !important;
  font-size: .85rem;
}

.invoice .invoicenumber {
  font-weight: 300;
  color: #666 !important;
}

.contry {
  padding-top: 50px;
}

.contryspan {
  color: #282828 !important;
  margin: 0 !important;
  font-weight: 700;
}

.tab-pane {
  background-color: #f9f6f3 !important;
}

.card {
  height: 100% !important;
}

.plusbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.btnspan {

  display: flex;
  align-items: center;
  justify-content: center;


}

.newcard:hover {
  color: #eb6517;

}

.deliver-content {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.addspan {
  cursor:pointer;
  display: flex;
  justify-content: center;
}

.addspan:hover {
  background-color: #eb6517 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

// ======================dropdown
.dropdownAuto {
  background-color: white;
  max-height: 200px;
  overflow: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
  z-index: 9;
  width: -webkit-fill-available;
  margin-right: 0.9rem;
  top: 4.5rem;

}

.dropdownItem {
  padding: 3px 10px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #ebd9c6;
  transition: .2s;
}


.dropdownAuto::-webkit-scrollbar {
  width: 5px;
}


/* Handle */
.dropdownAuto::-webkit-scrollbar-thumb {
  background: lightgrey;

}

/* Handle on hover */
.dropdownAuto::-webkit-scrollbar-thumb:hover {
  background: grey;
}
.tab-headers{
  text-align: center;
  display: flex;
  justify-content: center;
  background: #f9f6f3 !important;
}
.select-group-btn{
  padding: 8px !important;
  background: #e56216 !important;
  border: #e56216 thin solid !important;
}
.select-group-btn:hover{
  background: black;
}
.inputGroup{
  width: 100%;
}
.inputGroup .select2-selection__control{
width: 100% !important;
}
#OrderItemTable td{
  padding: 2px !important;
}
.invalid-select{
  border-top-color: #f46a6a !important;
  border-bottom-color: #f46a6a !important;
  border-left-color: #f46a6a !important;
  border-right-color: #f46a6a !important;
}
.loader.box{
  background-color: #f5f6f8 !important;
}
.loader .spinner{
  border-top-color: #eb6517 !important;
}
.loader.box .message, .loader.body .message {
  color: #282828  !important;
}

.toCapitalize{
  text-transform: capitalize;
}
.avatar-md {
  height: 2.8rem !important;
  width: 2.8rem !important;
}
.rounded-circle {
  border-radius: 50%!important;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f1f3f7;
  border: 1px solid #eff0f2;
  border-radius: 0.75rem;
}
.avatar-title {
  align-items: center;
  background-color: #fce9de !important;
  color: #eb6517 !important;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}
.bg-soft-primary {
  background-color: rgba(59,118,225,.25)!important;
}
a {
  text-decoration: none!important;
}
.badge-soft-danger {
  color: #f56e6e !important;
  background-color: rgba(245,110,110,.1);
}
.badge-soft-success {
  color: #63ad6f !important;
  background-color: rgba(99,173,111,.1);
}
.mb-0 {
  margin-bottom: 0!important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
}
.my-card{
  border-radius: 10px !important;
}
.active-card-box{
  background: #eb651705 !important;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.42) !important;
}
.sub-card-body{
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.my-address-badge{
  font-size: 11px !important;
  color: black !important;
  padding: 0px !important;
}

.custom-phone-input {
  display: none;
}

/* Style the input field */
.custom-phone-input {
  /* Add your desired styles here */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  border-radius: 5px;
}

// checkbox
.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: -37px;
  // margin-left: 110px;
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px; /* Add some spacing between checkboxes and text */
}

// expected delivery
.expedelivery-container {
  margin-top: -26px;
}

.calculated-date {
  margin-top: 5px;
  font-size: 12px;
  color: gray;
}