
#reporttableid th {
    text-align: center;
}

#reporttableid td:nth-child(1),
#reporttableid td:nth-child(2),
#reporttableid td:nth-child(4),
#reporttableid td:nth-child(5) {
    text-align: center;
}