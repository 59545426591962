//
// _header.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: $sidebar-width;
  z-index: 1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
  background: #f9f6f3;

  .dropdown .show {
    &.header-item {
      background-color: $gray-100;
    }
  }

  .navbar-brand-box {
    display: none;
  }
}

body {
  &.vertical-collpsed {
    #page-topbar {
      left: $sidebar-collapsed-width;
    }
  }

  &:not(.vertical-collpsed) {
    .navbar-header {
      .vertical-menu-btn {
        display: none;
      }
    }

    .app-search {

      margin-left: 10px;
    }
  }
}

.navbar-brand-box {
  padding: 0 1.5rem;
  width: $navbar-brand-box-width;
  position: fixed;
  z-index: 1;
  // background-color: $sidebar-bg;
  background: #f9f6f3;
}

.logo {
  line-height: 70px;

  .logo-sm {
    display: none;
  }
}

.logo-dark {
  display: $display-block;
}

.logo-light {
  display: $display-none;
}

.branchfield {
  .css-yk16xz-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    // border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: none !important;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
  }

}

//select border color
// .css-1pahdxg-control:hover  {
//   border-color: transparent!important;
// }
.branchfield {
  .css-1pahdxg-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: none !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-shadow: none !important;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
  }
}

//processing btn
.processbtn {
  color: #eb6517;
  background-color: #282828;
  font-size: 1rem;
  border-radius: 0.5rem;
  border-color: transparent !important;
}

.processbtn:hover {
  background-color: #535157 !important;
  color: #eb6517 !important;
}


/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 50px;
    padding-right: 20px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    border-radius: 5px;
    font-size: medium;
    // border: 1px solid #dadada;
  }

  span {

    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }
}

.vl {
  border-left: 1px solid #eee;
  height: 38px;
  left: 15%;
  margin-left: -3px;
  top: 0;
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: $dropdown-color;
    }
  }
}

//branch field
.branchfield {
  border-style: transparent !important;
  margin-left: 0px !important;
}

@media (max-width: 991.98px) {
  .navbar-brand-box {
    width: auto;
  }

  #page-topbar {
    left: 0;

    .navbar-header {
      .navbar-brand-box {
        display: inline-block;
        position: relative;
      }

      .vertical-menu-btn {
        display: inline-block;
      }
    }
  }

  .logo {
    span.logo-lg {
      display: none;
    }

    span.logo-sm {
      display: inline-block;
    }
  }
}

.page-content {
  // padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);
  padding: calc(#{$header-height} + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) 187px calc(#{$grid-gutter-width} / 2);

  background-color: #f9f6f3;
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
  padding: 3px;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 5px;
  }
}

.notification-item {
  .d-flex {
    padding: 0.75rem 1rem;
    display: flex;

    &:hover {
      background-color: $dropdown-link-hover-bg;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #282828 !important;

  img {
    height: 24px;

  }


  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $gray-200;
  }
}

// Full Screen
.fullscreen-enable {
  [data-bs-toggle="fullscreen"] {
    .uil-minus-path::before {
      content: "\eb8d";
    }
  }
}

body[data-topbar="dark"] {
  #page-topbar {
    background-color: $header-dark-bg;
  }

  .navbar-header {
    .dropdown .show {
      &.header-item {
        background-color: rgba($white, 0.05);
      }
    }

    .waves-effect .waves-ripple {
      background: rgba($white, 0.4);
    }
  }

  .header-item {
    color: $header-dark-item-color;

    &:hover {
      color: $header-dark-item-color;
    }
  }

  .header-profile-user {
    background-color: rgba($white, 0.25);
  }

  .noti-icon {
    i {
      color: $header-dark-item-color;
    }
  }

  .logo-dark {
    display: none;
  }

  .logo-light {
    display: block;
  }

  .app-search {
    .form-control {
      background-color: lighten($header-dark-bg, 4%);
      color: $white;
    }

    span,
    input.form-control::-webkit-input-placeholder {
      color: rgba($white, 0.5);
    }
  }
}

body[data-sidebar="dark"] {
  .navbar-brand-box {
    background: $sidebar-dark-bg;

    .logo-dark {
      display: none;
    }

    .logo-light {
      display: block;
    }
  }
}

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: none;
  }
}

body[data-layout="horizontal"] {
  .page-content {
    margin-top: $header-height;
    padding: calc(80px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2) $footer-height calc(#{$grid-gutter-width} / 2);

    @media (max-width: 991.98px) {
      margin-top: 0px;
    }
  }
}

// calc(70px + 1.25rem) calc(1.25rem / 2) 175px calc(1.25rem / 2);

@media (max-width: 374.99px) {
  .navbar-brand-box {
    padding: 0 1rem;
  }

  .language-switch {
    display: none !important;
  }
}

.searchcol .srchbtn {
  width: 40px;
  min-height: 38px;
  background-color: #eb6517;
  border-radius: 0.5rem;
  margin-top: 0rem !important;
  // padding: 10px;
  color: #fff;
  border: none !important;
}

.track {
  margin-left: 30px !important;
}