.MuiTabs-scroller{
    margin: -18px !important;
}
.MuiTabs-root{
    min-height: 30px !important;
}
.MuiTabs-indicator{
    margin: auto !important;
    bottom: 18px !important;
}
#shipmentDashboardTB1{
    width:100%;
    border-top:none;
    border-bottom: none;
}
#shipmentManifestoTB th{
    text-align: center;
}
#shipmentManifestoTB td:last-child{
    text-align: left;
}