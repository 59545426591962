#vattable th{
  text-align: center;
}


#vattable td:nth-child(1){
  text-align: center;
}
#vattable td:nth-child(2){
  text-align: right;
}

