.trackingh1{
    font-size: 1.25rem;
    font-weight: 700;
    color:#282828 ;
}
.trackingcard{
    height: auto !important;
    border-radius: .5rem !important;
    padding: 0px !important;
    // box-shadow: 20px 20px 20px 0 RGB(0 0 0 / 5%) !important;
}

.srchcol{
    display: flex;
    margin-top: 0rem !important;
}
.srchcol input{
    width: 100%;
    padding: 10px 15px;
    border-radius: .5rem;
    border:1px solid #ccc;
    outline: 0;
    font-size: 1rem !important;
}

.srchcol .srchbtn{
    width: 50px;
    background-color: #eb6517;
    border-radius: 0.5rem;
    margin-left: 10px;
    color: #fff;
    border: none !important;
}
.col4trackingcard{
    border-radius: .5rem !important;
    padding: 0 !important;
    box-shadow: 20px 20px 20px 0 RGB(0 0 0 / 5%) !important;
}
.col4mainhead .iconcol4{
    width: 36px;
    margin-right: 15px;
}
.col4mainhead{
    display: flex;
    background-color: #282828;
    border-radius: .5rem;
}
.mainheadh6{
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
}

.statuscol4{
    font-size: .9rem;
    font-weight: 600;
    color: #fff !important;
}
.statuscol4 .red{
    font-size: 1rem; 
    font-weight: 600;
    color: red !important;
}
.qtybox{
   display: flex;
   position: absolute !important;
   right: 1rem !important;
   top: 1.9rem !important;
   align-items: center;
   font-size: 1rem;
    font-weight: 700;
    color: #fff;
}
.orderdetails{
    display: flex;
    align-items: center;
    
}


.orderdetails .iconship {
    height: 40px;
    width: 40px;
    border: 1px solid #282828;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #eb6517;
    z-index: 9;
 background-color: #fff;
 
}


.shipmentbooked{
    font-size: 1.1rem;
    font-weight: 700;
    color: #282828;
}


.collapsedetails{
    margin-left: 5rem !important;
}
.srcresult{
    color: #666 !important;
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    margin-bottom: 0rem !important;
}
.srcresult span{
    color: #333 !important;
    font-weight: 700 !important;
}
.resulticon{
    width: 30px;
}
.resultcardtrack{
    cursor: pointer !important;
   border-radius: .5rem !important;
   padding: 0 !important;
   margin: .5rem !important;
   height: unset !important;
}
.resultmain{
    display: flex;
    
}
.resultcardbodytrack{
    padding: 0rem 1.25rem !important;
}
.resultweight{
    position: absolute;
    right: 3.5rem !important;
    top: 1.5rem;
     font-size: 1.1rem !important;
        font-weight: 700 !important;
        color: #282828 !important;
}
.arrowbutton{
    position: absolute;
    right: 1.8rem;
    top: 1.5rem;
    font-size: 1.1rem !important;
        font-weight: 700 !important;
        color: #282828 !important;
}

.resultmain .detailsp{
        font-size: 1.1rem !important;
        font-weight: 700 !important;
        color: #282828 !important;
}
hr.new2 {
    border-top: 1px dashed red;
  }
  
.maindetailcard{
  background-color: #f9f6f3 !important;
 max-height: 50vh !important;
 overflow-y: auto;
 box-shadow: none !important;
}

.maindetailcard::-webkit-scrollbar {
    width: 5px  !important;
  }
  
//   /* Track */
//   .maindetailcard::-webkit-scrollbar-track  {
//     background: #f1f1f1; 
//   }
   
  /* Handle */
  .maindetailcard::-webkit-scrollbar-thumb  {
    background:#282828; 
  }

.bookedp{
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: #282828 !important;
}
.bookedref{
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: #282828 !important;

}
.resultcardtrackbox{
    cursor: pointer !important;
    border-radius: .5rem !important;
    padding: 0 !important;
    margin: .5rem !important;
    height: auto !important;
 }
 
.orderdetails::before{
    content: '';
    display: flex;
    width: 0;
    height: calc(100% - 13.5rem);
    position: absolute;
    left: 2.4rem;
    bottom: 4rem;
    border-right: 1px dashed;
    z-index: 1;
    

}

  
  