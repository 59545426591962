#tableReport th{
    text-align: center;
}
#tableReport td:nth-child(2){
    width: 160px;
}
#tableReport td:nth-child(5),
#tableReport td:nth-child(6){
    text-align: right;
}
#tableReport td:nth-child(3),
#tableReport td:nth-child(5),
#tableReport td:nth-child(6){
    width: 100px;
}
#tableDayBook td:nth-child(3){
    text-align: right;
}
