#AddOffercodetable th {
    text-align: center;
    }
      #AddOffercodetable td:nth-child(7){
        width:5%;
        text-align: right;
      }
      #AddOffercodetable td:nth-child(3){
        // text-align: center;
        width:10%;
    }
    #AddOffercodetable td:nth-child(4){
         text-align: center;
        width:10%;
    }
    #AddOffercodetable td:nth-child(5){
         text-align: center;
        width:10%;
    }
    //  #AddFaqtable td:nth-child(2),
     #AddOffercodetable td:nth-child(6)
     {
        text-align: right;
       }
     #AddOffercodetable td:nth-child(10){
     text-align: right;
    }
    #AddOffercodetable td:nth-child(9){
        text-align: right;
       }
    #AddOffercodetable td:nth-child(8){
        text-align: right;
       }
   