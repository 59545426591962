.status {
    background-color: white;
    box-shadow: 0 0 20px #cfcfcf75;
    //padding: 10px 16px;
    border-radius: 5px;
    margin: 16px 0;
    height: 100%;
}

.status12 {
    //padding: 10px;
}
.paraa1 {
    background-color: #363030;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 0.8rem;
    color: white;
    display: flex;
}
.status img {
    width: 35px;
    background-color: white;
    position: relative;
    z-index: 1;
    border-radius: 50%;
}
.head-details {
    color: white;
    padding-left: 15px;
}
.status1 {
    display: flex;
    padding: 10px 0;
    padding-left: 10px;
}
.status-img {
    width: 40px;
}
.status-details {
    padding-left: 15px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-flow: column;
    width: calc( 100% - 40px);
}
.status-details p {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: black;
}
.para-sub {
    color: #838383 !important;
}
.line{
    height: 236px;
    width: 4px;
    background-color: #f7801f;
    position: absolute;
    left: 35px;
    top: 100px;
}
.head-details p {
    margin-bottom: 0;
    color: white !important;
}
.head-details > p .delivered{
    color:#34c38f !important;
}
.head-details p .undelivered{
    color:#cc3030 !important;
}