#Addstatetable th {
  text-align: center;
}

//  #AddFaqtable td:nth-child(2),
#Addstatetable td:nth-child(6) #Addstatetable td:nth-child(10) {
  text-align: center;
}

#Addstatetable td:nth-child(1){
  text-align: left;
}

// #Addstatetable td:nth-child(10){
//     text-align: center;
//   }