#Itemtable th {
  text-align: center;
}

// #Itemtable td:nth-child(12),
// #Itemtable td:nth-child(11),
// #Itemtable td:nth-child(10),
// #Itemtable td:nth-child(9),
// #Itemtable td:nth-child(8),
// #Itemtable td:nth-child(7),
// #Itemtable td:nth-child(6),

#Itemtable td:nth-child(6){
  text-align: right;
}

#Itemtable td:nth-child(1),
#Itemtable td:nth-child(4),
#Itemtable td:nth-child(13){
  text-align: center;
}
.form-selects.select2-selection__control{
  height: 35px !important;
  min-height: 24px !important;
}
