
#BranchTableId td:nth-child(2){
    text-align: left;
}
#BranchTableId td:nth-child(1){
    text-align: center;
}
#BranchTableId th {
    text-align: center;
    }
    #BranchTableId td:nth-child(7){
        text-align: left;
    }
    