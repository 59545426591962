.deliver-badge {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    position: absolute;
    top: -9px;
    background: #eb6517;
    padding: 0px 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
    height: 18px;
    color: white !important;
    font-size: 10px;
    font-weight: 500;
    border-radius: 15px;
  }
  .deliver-card:hover{
    border:2px solid #eb6517!important;
    background-color: #fbfbfb;
    //transform: scale(1.05);
    transition: .2s;
  }
  .deliver-card{
    border:2px solid transparent!important;
  }


  .international{
    padding-top: 100px;
    padding-left: 20px;
    padding-bottom: 20px;
    background-color: #f9f6f3;
  }
  .navhead{
   font-size:larger!important; 
   
  }
  
  .nav-link{
    padding: 0.5rem 4rem !important;
  }
  .nav-pills .nav-link, .nav-pills .show > .nav-link {
      padding: 10px 20px !important;
      border-radius: 0.5rem  !important;
      background-color: transparent  !important;
      color: #282828  !important;
      border: none  !important;
      display: flex  !important;
      border: 1px solid  !important;
      margin: 0 10px!important;
   
  }
  
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff!important;
    background-color: #eb6517!important;
   
  }
  .nav-link:hover, .nav-link:focus {
    color: #eb6517!important;
  }
  
  .nav-pills .nav-link :active {
    color: #ffffff!important;
    background-color: #eb6517 !important;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    
  }
  .table-bordered{
    border-color: #f1f1f3 !important;
  }
  .addnewbtn{
     padding: 5px 15px!important;
      border-radius: 0.5rem !important;
      background-color: #fbd9c4 !important;
      font-size: .75rem !important;
      color: #333 !important;
      display: block !important;
      font-weight: 600 !important;
      margin: auto !important;
      margin-top: -2rem !important;
      position: relative !important;
      opacity: 0 !important;
  
  }
  .addnewbtn:hover {
    background-color: #ffba8e !important;
  }
  table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
    border-bottom-width: 1px !important;
    background-color: #fff;
  }
  
  thead{
    border-width: 1px!important;
    background-color: #fff;
  }
  .content{
    text-align: left;
    margin-top: 0!important;
    
  }
  .content1{
    text-align: right;
  }
  .imglogo{
    width: 100%;
    margin-left: 40px
  }
  .headcontent{
    display: flex;
    justify-content: center;
  }
  .alfarha{
    font-weight: 700;
    color:#282828
  }
  .icons{
    color: #eb6517;
  }
  .details{
    color:#666!important;
  }
  .sumhead{
      background-color: #ffe2d1;
      padding: 8px 15px;
      border-radius: 0.3rem;
      color: #eb6517;
  }
  .tablecell{
    width: 0px;
    text-align: center!important;
    font-weight: bold;
    padding-left: 0rem;
    padding-right: 0rem;
    font-weight: 700;
    font-size: 12.819px;
    color: #495057;
    
  }
  .sumcontent{
    display: flex !important;
    justify-content: flex-end;
    
  }
  .btnlist{
      display: flex;
      justify-content: flex-end;
     
      margin-top: 1rem;
  }
  .btnlist .back{
      padding: 8px 20px;
      border-radius: 2rem;
      background-color: #282828;
      color: #fff;
      border:none;
      
  
  }
  .btnlist .refresh{
    padding: 8px 20px;
    
    border-radius: 2rem;
    background-color: #282828;
    color: #fff;
    border: none;
    
  
  }
  .btnlist .refresh:hover{
    background-color: #535157;
    box-shadow: 12px 9px 20px;
  }
  .btnlist .back1{
    padding: 8px 20px;
    border-radius: 2rem;
    background-color: #eb6517;
    color: #fff;
    border:none;
    
  
  }
  .btnlist  .submit {
    width: 120px;
    border-radius: 2rem;
    background-color: #eb6517;
    color: #fff!important;
    border:none;
  }
  
  .btnlist .submit:hover {
    background-color: #cd4b00;
  }
  .btnlist .back:hover{
    background-color: #535157;
    box-shadow: 12px 9px 20px;
  }
  .btnlist .back1:hover{
    background-color: #585858 !important;
  }
  .tab-content{
    background-color: #fff!important;
  }
  .thcontent{
    width: 200px;
    color: #666;
    font-weight: 700;
   
  }
  .tdcontent{
    padding-right: 10px;
  }
  
  .totalcontent{
    color: #eb6517!important;
    font-weight: 700;
   
  }
  .invoice{
      font-weight: 700;
      color: #333!important;
      font-size: .85rem;
  }
  .invoice .invoicenumber{
    font-weight: 300;
    color: #666!important;
  }
  .contry{
    padding-top: 50px;
  }
  .contryspan{
    color: #282828!important;
    margin: 0 50px;
    font-weight: 700;
  }
  .tab-pane{
    background-color: #f9f6f3!important;
  }
  .card{
    height: 100%!important;
  }
  .plusbtn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
  }
  .btnspan{
   
      display: flex;
      align-items: center;
      justify-content: center;
     
   
  }
  .newcard:hover{
    color: #eb6517;
  }
  .deliver-content{
    display: flex; 
    align-items: center;
    gap: 8px; 
    justify-content: space-between;
  }
  .addspan{
    display: flex;
    justify-content: center;
  }
  .addspan:hover{
    color: #eb6517;
  }
  // .deliver-badge i{
  //   font-size: 10px;
  //   line-height: 18px;
  // }
  
  // .page-content-success{
  //   height: 60vh;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  // }
  
  // .deliver-card .card{
  //   border:2px solid transparent!important;
  
  // }
  // .deliver-card .card:hover{
  //   border:2px solid #eb6517!important;
  //   background-color: #fbfbfb;
  //   //transform: scale(1.05);
  //   transition: .2s;
  // }
  .order-title-label{
    text-align: center;
    /* font-size: 1.2rem; */
    /* font-weight: 700; */
    color: rgb(40, 40, 40);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
    -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .pckg-box img{
    width: 60px;
    height: 60px;
  }
.packg-count{
  position: absolute;
  margin-top: -20px;
  margin-left: 38px;
  background: white;
  border-radius: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  padding: 1px;
  border: #fb7216 thin solid;
  width: 23px;
}
.max-label{
  font-size: 10px;
    font-weight: bold;
    color: #f10000;
}

 