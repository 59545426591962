#Addnotificationtable th {
    text-align: center;
    }
    
      #Addnotificationtable td:nth-child(1){
        text-align: center;
       }
       #Addnotificationtable td:nth-child(2){
        text-align: center;
       }
    //    #Addnotificationtable td:nth-child(4)
       #Addnotificationtable td:nth-child(5){
        text-align: center;
       }
    //  #Addnotificationtable td:nth-child(6)
     #Addnotificationtable td:nth-child(7){
    width: 20%;
    }
    // #Addstatetable td:nth-child(10){
    //     text-align: center;
    //   }