.resultcardbodytrack {
    padding: 0rem 1.25rem !important;
}

.resultmain .detailsp {
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: #282828 !important;
}
.resultmain .detailsp-box {
    font-weight: bold !important;
    color: #282828 !important;
}

.resulticon {
    width: 30px;
}

.resultweight {
    position: absolute;
    right: 3.5rem !important;
    top: 1.5rem;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
    color: #282828 !important;
}
.resultweight-box {
    position: absolute;
    // right: 2em !important;
    top: 1.5rem;
    font-size:13px !important;
    font-weight: 700 !important;
    color: #282828 !important;
}

.maindetailcard {
    background-color: #f9f6f3 !important;
    max-height: 50vh !important;
    overflow-y: auto;
    box-shadow: none !important;
}

.resultcardtrack {
    cursor: pointer !important;
    border-radius: .5rem !important;
    padding: 0 !important;
    margin: .5rem !important;
    height: unset !important;
}

.resultcardbodytrack {
    padding: 0rem 1.25rem !important;
}

.trackingcard-cardbody {
    padding: 0px !important;

}

.select2-selection {
    overflow: scroll;
}

.maincard {
    background-color: #f9f6f3 !important;
    max-height: 50vh !important;
    overflow-y: auto;
    box-shadow: none !important;
    width: 122%;
    cursor: pointer;
}

.maindetailcard::-webkit-scrollbar {
    width: 5px  !important;
}
.box-div{
    box-shadow: -4px 4px 20px RGB(0 0 0 / 15%) !important;
    min-height: 344px;
}
.tooltips {  
    position: relative;
    width: 400px;
    height: 250px;
    padding: 0px;
    background: #f9f6f3;
    border:#f9f6f3 solid 1px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
  }
  
  .tooltips:after 
  {
    content: " ";
    position: absolute;
    left: -15px;
    top: var(--tooltips-top);
    border-top: 15px solid transparent;
    border-right: 15px solid #f9f6f3;
    border-left: none;
    border-bottom: 15px solid transparent;
  }
  .a-box-all{
    text-align: right;
    padding: 5px;
  }
  .a-box-all label{
    font-weight: bold;
  }
  .a-box-all a{
    color: black;
    font-weight: bold;
    cursor: pointer;
    padding:10px
  }
  .a-box-all a:hover{
    color: #eb6517 !important;
  }