#ComplaintId th{
    text-align: center;
}
#ComplaintId td:nth-child(1),
#ComplaintId td:nth-child(2),
// #ComplaintId td:nth-child(3),
#ComplaintId td:nth-child(9),
#ComplaintId td:nth-child(11){
    text-align: center;
}
#ComplaintId td:nth-child(7){
   max-width: 130px;
}
#ComplaintId td:nth-child(8){
    text-align: center;
 }
#ComplaintId td:nth-child(2){
    min-width: 97px;
 }
#complaintPopupTableDataId th{
    text-align: center;
}
#complaintPopupTableDataId td:nth-child(1),
#complaintPopupTableDataId td:nth-child(2),
#complaintPopupTableDataId td:nth-child(4){
    text-align: center;
}

#ComplaintId td:nth-child(2){
    width: 100px;
    text-align: center;

}
#ComplaintId td:nth-child(3){
    width: 100px;
    text-align: left;
}
#ComplaintId td:nth-child(4){
    width: 120px;
    text-align: center;
}

#product_available_day {
    width: 100%;
}

#product_available_day td {
    padding: 5px;
}

#compnttbleId th {
    text-align: center;
}
#compnttbleId td:nth-child(1),
#compnttbleId td:nth-child(3),
#compnttbleId td:nth-child(4),
#compnttbleId td:nth-child(8) {
    text-align: center;
}
