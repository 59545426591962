#BookingEnqtable th{
    text-align:center;
}

#BookingEnqtable td:nth-child(5),
#BookingEnqtable td:nth-child(3),
#BookingEnqtable td:nth-child(2),
#BookingEnqtable td:nth-child(1)
{
    text-align:center;
}


#BookingEnqtable td:nth-child(1)
{
    width:1px;
}
#BookingEnqtable td:nth-child(2)
{
    width:95px;
}
#BookingEnqtable td:nth-child(3)
{
    width:75px;
}
