@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

// #daywiseReportId td:nth-child(3),
// #daywiseReportId td:nth-child(5),
// #daywiseReportId td:nth-child(6){
//     text-align: right;
// }
#bookingTableId td:nth-child(1),
#bookingTableId td:nth-child(2),
#bookingTableId td:nth-child(3),
#bookingTableId td:nth-child(9),
#bookingTableId td:nth-child(10) {
    text-align: center;
}

#bookingTableId td:nth-child(9) {
    text-align: right;
}

#bookingTableId th {
    text-align: center;
}

.col-cls-cls {
    flex: 1;
    padding: 10px;
}

.boxes-bx {
    text-align: center;
}

.boxes-bx1 {
    text-align: right;
}

.declare-eng {
    color: #fff;
    text-align: start;
}

.declare-arab {
    color: #fff;
    text-align: end;
    margin-top: 1px;
}
// #bookingTableId td:nth-child(2) {
//     min-width: 100px;
// }

#weighttableid {
    width: 100%;
    padding: 5px;
}
#bookingTableId .select2-selection__control {
    height: 35px !important;
    min-height: 30px !important;
}

#bookingTableId .select2-selection__indicator {
    padding: 5px !important;
}

.window-close {
    position: relative;
    top: -14px;
    right: 5px;
    font-size: 20px;
    color: #fe6400;
}

#receiverAddTable th:nth-child(1) {
    width: 100px;
}

#senderAddTable {
    width: 75%;
    // float: right;
}

#senderAddTable th:nth-child(1) {
    width: 65px;
    text-align: left;
}

// #senderAddTable th:nth-child(3) {
//     text-align: right;
// }

#receiverAddTable th:nth-child(2),
#senderAddTable th:nth-child(2) {
    width: 14px;
    text-align: center;
}

#summeryItemsTable {
    width: 100%;
}

.box-dsh-box {
    border-right: 1.5px dashed #6d6b6b;
}

#summeryItemsTable th:nth-child(5),
#summeryItemsTable th:nth-child(6),
#summeryItemsTable th:nth-child(4),
#summeryItemsTable td:nth-child(4),
#summeryItemsTable td:nth-child(5),
#summeryItemsTable td:nth-child(6) {
    text-align: right;
}

#summeryItemsTable th:nth-child(3) {
    text-align: center;
}

#summeryItemsTable td:nth-child(3) {
    text-align: center;
}

#summeryItemsTable td:nth-child(2),
#summeryItemsTable td:nth-child(4),
#summeryItemsTable td:nth-child(5),
#summeryItemsTable td:nth-child(6) {
    width: 100px;
}

#summeryTableID {
    width: 100%
}

.hr-dashed {
    border: 1.5px dashed #000;
}

.vertical-line {
    border: 1.5px dashed #000; /* Adjust the color and size as needed */
    height: 100%;
    margin: 0 10px; /* Adjust the margin as needed */
}

.with-dashed-border {
    border-right: 1px dashed #000; /* Adjust the color and thickness as needed */
    // padding-right: 10px; /* Add some spacing to separate the columns */
}

.with-line-border {
    border-left: 1px solid #000; /* Adjust the color and thickness as needed */
    padding-left: 10px; /* Add some spacing to separate the columns */
}

.with-dashed-border1 {
    border-top: 1px dashed #000; /* Adjust the color and thickness as needed */
    padding-top: 10px; /* Add some spacing to separate the columns */
}

#summeryItemsTable th,
#summeryBoxTable th {
    // border-top: 1.5px dashed #6d6b6b;
    border-bottom: 1.5px dashed #000;
    padding-top: 10px;
    padding-bottom: 10px;
}

#summeryBoxTable td {
    padding: 0px;
    font-size: 11px;
}

#summeryItemsTable td:nth-child(5) {
    width: 118px;
}

#summeryItemsTable td:nth-child(2) {
    width: 118px;
}

#summeryBoxTable {
    width: 100%;
}

#summeryBoxTable td:nth-child(5) {
    width: 100px
}

#summeryBoxTable th:nth-child(2),
#summeryBoxTable td:nth-child(2) {
    text-align: center;
    width: 200px
}

#summeryBoxTable td:nth-child(4),
#summeryBoxTable th:nth-child(4) {
    padding-left: 20px;
}

#summeryBoxTable td:nth-child(3) {
    text-align: right;
}

.alfarha2 {
    font-weight: 700;
    color: #282828
  }

#adddomestictableid {
    width: 100%;
}

#adddomestictableid th:nth-child(1),
#adddomestictableid th:nth-child(3) {
    text-align: center;
}

#adddomestictableid th:nth-child(4),
#adddomestictableid th:nth-child(5),
#adddomestictableid th:nth-child(6) {
    text-align: right;
}

#adddomestictableid th:nth-child(2) {
    text-align: left;
}

#adddomestictableid td:nth-child(1),
#adddomestictableid td:nth-child(3) {
    text-align: center;
}

#adddomestictableid td:nth-child(4),
#adddomestictableid td:nth-child(5) {
    text-align: right;
}

#adddomestictableid td:nth-child(2) {
    text-align: left;
    font-weight: 600;
}

#adddomestictableid td:nth-child(6) {
    text-align: right;
    font-weight: 600;
}
// =========
#invoicetableid {
    width: 100%;
}

.SlNoColumn {
    width: 5%;
}

#invoicetableid td:nth-child(1),
#invoicetableid td:nth-child(3) {
    text-align: center;
}

#invoicetableid td:nth-child(2) {
    text-align: left;
}

#invoicetableid th:nth-child(3) {
    text-align: center;
}

#invoicetableid th:nth-child(2) {
    text-align: left;
}

#invoicetableid th:nth-child(4),
#invoicetableid th:nth-child(5),
#invoicetableid th:nth-child(6) {
    text-align: right;
}

#invoicetableid td:nth-child(4),
#invoicetableid td:nth-child(5),
#invoicetableid td:nth-child(6) {
    text-align: right;
}

#invoicetableid td:nth-child(2),
#invoicetableid td:nth-child(6) {
    font-weight: 600;
}

// =====
#summeryItems1Table {
    width: 100%;
}

#summeryItems1Table th:nth-child(5),
#summeryItems1Table th:nth-child(6),
#summeryItems1Table th:nth-child(4),
#summeryItems1Table td:nth-child(4),
#summeryItems1Table td:nth-child(5),
#summeryItems1Table td:nth-child(6) {
    text-align: right;
}

#summeryItems1Table td:nth-child(2),
#summeryItems1Table td:nth-child(4),
#summeryItems1Table td:nth-child(5),
#summeryItems1Table td:nth-child(6) {
    width: 100px;
}

#summeryItems1Table th {
    padding-top: 5px;
    padding-bottom: 10px;
}

.second-line-value {
    font-weight: 700;
    font-size: 2rem;
}

#summeryItemsTable1 {
    width: 100%;
}

#summeryItems1Table th {
    padding-top: 5px;
    padding-bottom: 10px;
}

.dashed-border {
    border-top: 1px dashed #000;
}






