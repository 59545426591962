#branchReport th{
    text-align: center;
}
#branchReport td:nth-child(2){
    width: 160px;
}
#branchReport td:nth-child(5),
#branchReport td:nth-child(6){
    text-align: right;
}
#branchReport td:nth-child(3),
#branchReport td:nth-child(5),
#branchReport td:nth-child(6){
    width: 100px;
}