#AddFaqcategorytable th {
    text-align: center;
    }
    
      #AddFaqcategorytable td:nth-child(1),
    //  #AddFaqtable td:nth-child(2),
    //  #AddFaqcategorytable td:nth-child(6)
     #AddFaqcategorytable td:nth-child(3){
        text-align: center;
        width:10%;
    }
    // #Addstatetable td:nth-child(10){
    //     text-align: center;
    //   }