#AddFaqtable th {
    text-align: center;
    }
    
    //   #AddFaqtable td:nth-child(1){
    //     width:10%;
    //   }
      #AddFaqtable td:nth-child(3){
        // text-align: center;
        width:20%;
    }
    //  #AddFaqtable td:nth-child(2),
     #AddFaqtable td:nth-child(6)
     #AddFaqtable td:nth-child(10){
     text-align: center;
    }
    // #Addstatetable td:nth-child(10){
    //     text-align: center;
    //   }