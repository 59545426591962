.dropdownAuto{
  background-color: white;
  
  max-height: 200px;
  overflow: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdownItem{
  padding: 7px 10px;
  cursor: pointer;
}
.dropdownItem:hover{
  background-color: #d5e2ee;
  transition: .2s;
}


.dropdownAuto::-webkit-scrollbar {
  width: 5px;
}


/* Handle */
.dropdownAuto::-webkit-scrollbar-thumb {
  background: lightgrey; 
  
}

/* Handle on hover */
.dropdownAuto::-webkit-scrollbar-thumb:hover {
  background: grey; 
}