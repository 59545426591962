.processbound {
    font-size: 1.25rem;
    font-weight: 700;
    color: #282828;
}

.pagination {
    z-index: 0;
    position: relative;
}

// .selected::after {
//     content: '';
//     display: inline-block;
//     width: 16px;
//     height: 16px;
//     background-image: url('blue-tick.png');
//   }
  