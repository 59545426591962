// #daywiseReportId td:nth-child(3),
// #daywiseReportId td:nth-child(5),
// #daywiseReportId td:nth-child(6){
//     text-align: right;
// }
#MonthReportTableId td:nth-child(5),
#MonthReportTableId td:nth-child(1),

#MonthReportTableId td:nth-child(3),
#MonthReportTableId td:nth-child(4),
#MonthReportTableId td:nth-child(6),
#MonthReportTableId td:nth-child(7){
    text-align: center;
}

#MonthReportTableId td:nth-child(8),
#MonthReportTableId td:nth-child(9){
    text-align: right;
}
#MonthReportTableId th{
    text-align: center;
}
#MonthReportTableId td:nth-child(2){
   min-width: 100px;
   text-align: left;
}