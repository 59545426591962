#stockreportTable th{
    text-align: center;
}
#stockreportTable td:nth-child(1),
#stockreportTable td:nth-child(2),
#stockreportTable td:nth-child(3),
#stockreportTable td:nth-child(6),
#stockreportTable td:nth-child(7),
#stockreportTable td:nth-child(8),
#stockreportTable td:nth-child(9){
    text-align: center;
}
#stockreportTable td:nth-child(2){
    width: 100px;
}
#stockreportTable td:nth-child(6),
#stockreportTable td:nth-child(7),
#stockreportTable td:nth-child(8){
    width: 70px;
}