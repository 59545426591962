//
// authentication.scss
//

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
}

.authentication-bg {
  background: url("../../../images/bg.jpg");
  background-size: cover;
  background-position: center;
  //position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
  top: 0;
  //background-color: $auth-bg;
  .auth-logo {
    .logo {
      margin: 0px auto;
      width: 100%;
      height: auto;
    }
  }
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $border-color;
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: $card-bg;
    padding: 2px 16px;
  }
}

// auth carousel

.auth-carousel {
  &.owl-theme {
    .owl-dots {
      .owl-dot {
        span {
          background-color: $card-bg;
        }
        &.active {
          span {
            background-color: $primary;
          }
        }
      }
    }
  }
}
.account-pages-container {
  // background: url("../../../images/bg.jpg");
  // background-size: cover;
  // background-position: center;
  // position: absolute;
  // background-size: cover;
  // height: 100%;
  // width: 100%;
  // top: 0;
  .account-pages {
    a {
      color:white;
      @media only screen and (max-width: 600px) {
        display: block;
      }
    }
    .right-button {
      text-align: right;

      .btn {
        background-color: #67a8e4;
        border-color: #67a8e4;
      }
    }
  }
}
a.test_hover :hover{
  background-color: rgb(199, 221, 247) !important;
  color: black !important;
}

// .grand_total_label {
  
//   float: right;
//   position: relative;
//   bottom: 60px;
//   right: 250px;
//   font-size: 14px;
//   font-weight: 600;
// }
// .grand_total_value {
//   float: right;
//   position: relative;
//   bottom: 60px;
//   right: 83px;
//   font-size: 14px;
//   font-weight: 600;
// }
